import { action, makeAutoObservable, observable } from "mobx";
import SvjStore from "../Svj";

class DashboardStore {
  revenueThisMonth = 0;
  revenueThisYear = 0;
  overdueInvoices = 0;
  revenueLastYear = 0;
  invoices = [];
  invoicesLastYear = [];
  loading = false;
  constructor() {
    makeAutoObservable(this, {
      setValue: action,
    });
  }

  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value;
  }

  async getData() {
    this.setValue("loading", true);
    const stats = await SvjStore.getStats();
    if (stats?.revenueThisMonth) {
      this.setValue(
        "revenueThisMonth",
        stats.revenueThisMonth.replace(".", ",").toLocaleString("cs-CZ", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    }
    if (stats?.revenueThisYear) {
      this.setValue(
        "revenueThisYear",
        stats.revenueThisYear.replace(".", ",").toLocaleString("cs-CZ", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    }

    if (stats?.revenueLastYear) {
      this.setValue(
        "revenueLastYear",
        stats.revenueLastYear.replace(".", ",").toLocaleString("cs-CZ", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    }
    if (stats?.overdueInvoices) {
      this.setValue(
        "overdueInvoices",
        stats.overdueInvoices.replace(".", ",").toLocaleString("cs-CZ", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    }

    if (stats?.invoices) {
      this.setValue("invoices", stats.invoices);
    }
    if (stats?.invoicesLastYear) {
      this.setValue("invoicesLastYear", stats.invoicesLastYear);
    }
    this.setValue("loading", false);
  }
}
export default new DashboardStore();
