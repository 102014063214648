/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useIntl } from "react-intl";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import { getRandomKey, getTranslation } from "../../../../app/Helpers/Default";
import { observer } from "mobx-react";
import Invoices from "../../../../app/Pages/Invoices";

export const AsideMenuMain = observer(() => {
  const intl = useIntl();

  return (
    <>
      <AsideMenuItem
        to="/nastenka"
        // icon="/media/icons/duotune/maps/map009.svg"
        title={getTranslation("MENU.DASHBOARD", intl)}
        bold={true}
        fontIcon="fas fa-tachometer-alt pe-3"
        // icon='/media/icons/duotune/maps/map009.svg'
      />
      {/*<div className='menu-item'>*/}
      {/*  <div className='menu-content pt-8 pb-2'>*/}
      {/*    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <AsideMenuItem
        to={`/objednavky`}
        title={getTranslation("ADMIN.MENU.ORDERS", intl)}
        // icon="/media/icons/duotune/general/gen014.svg"
        key={getRandomKey()}
        fontIcon={"fas fa-pen-square pe-4"}
        visible={true}
      />

      <AsideMenuItem
        to={`/faktury`}
        title={getTranslation("ADMIN.MENU.INVOICE", intl)}
        // icon="/media/icons/duotune/general/gen014.svg"
        fontIcon={"fas fa-file-invoice pe-5"}
        key={getRandomKey()}
        visible={true}
      />

      <AsideMenuItem
        to={`/svjs`}
        title={getTranslation("ADMIN.MENU.SVJS", intl)}
        // icon="/media/icons/duotune/general/gen014.svg"
        fontIcon={"fas fa-building pe-4"}
        key={getRandomKey()}
        visible={true}
      />
    </>
  );
});
