import { hasCommitteeRole, hasCrudRole } from "../../Helpers/User";
export const EVENTS_MODULE = "events";
export const ROOMS_MODULE = "rooms";
export const MESSAGES_MODULE = "messages";
export const USERS_MODULE = "members";
export const VOTE_MODULE = "vote";
export const SVJ_MODULE = "svj";
export const SVJ_FEE_MODULE = "roomFees";
export const INVOICES_MODULE = "invoices";
export const MEETING_OWNERS_MODULE = "meetingOwners";

export const REAL_ESTATE_CADASTRE_MODULE = "real_estate_cadastre";
export const BANKRUPTCY_REGISTER_MODULE = "bankruptcy_register";
export const GALLERY_MODULE = "gallery";
export const ACCEPT_READ_MODULE = "acceptRead";

export const REQUIREMENT_FOR_APPROVAL_MODULE = "requirement_for_approval";

export const CASH_DESK_MODULE = "cashDesk";

export const ORDER_MODULE = "order";

export const SURVEY_MODULE = "survey";

export const CONTACT_MODULE = "contact";

export const ROLE_MODULE = "role";
export const DOCUMENTS_MODULE = "documents";
export const REPORTS_MODULE = "reports";

export const MODULES = [
  {
    id: EVENTS_MODULE,
    visible: true,
    name: "MODULES.EVENTS",
    notifications: "MODULES.EVENTS.NOTIFICATIONS",
  },
  {
    id: DOCUMENTS_MODULE,
    visible: true,
    name: "MODULES.DOCUMENTS",
    notifications: "MODULES.DOCUMENTS.NOTIFICATIONS",
  },
  {
    id: MESSAGES_MODULE,
    visible: true,
    name: "MODULES.MESSAGES",
    notifications: "MODULES.MESSAGES.NOTIFICATIONS",
  },
  {
    id: USERS_MODULE,
    visible: hasCommitteeRole(),
    name: "MODULES.OWNERS",
    notifications: "MODULES.OWNERS.NOTIFICATIONS",
    disabledNotifications: true,
  },
  {
    id: ROOMS_MODULE,
    visible: true,
    name: "MODULES.ROOMS",
  },
  {
    id: "reports",
    visible: true,
    name: "MODULES.REPORTS",
    notifications: "MODULES.REPORTS.NOTIFICATIONS",
  },
  {
    id: "survey",
    visible: true,
    name: "MODULES.SURVEYS",
    notifications: "MODULES.SURVEYS.NOTIFICATIONS",
  },
  {
    id: VOTE_MODULE,
    visible: true,
    name: "MODULES.VOTES",
    notifications: "MODULES.VOTES.NOTIFICATIONS",
    disabledNotifications: true,
  },
  {
    id: MEETING_OWNERS_MODULE,
    visible: true,
    name: "MODULES.OWNERS_MEETINGS",
    notifications: "MODULES.OWNERS_MEETINGS.NOTIFICATIONS",
  },
  {
    id: "gallery",
    visible: true,
    name: "MODULES.GALLERY",
  },

  {
    id: "svj",
    visible: true,
    name: "MODULES.SVJ",
  },

  {
    id: "contact",
    visible: true,
    name: "MODULES.CONTACT",
  },

  {
    id: CASH_DESK_MODULE,
    visible: true,
    name: "MODULES.CASH_DESK",
  },

  {
    id: SVJ_FEE_MODULE,
    visible: hasCrudRole(SVJ_FEE_MODULE),
    name: "MODULES.ROOM_FEES",
  },

  {
    id: INVOICES_MODULE,
    visible: true,
    name: "MODULES.INVOICES",
    notifications: "MODULES.INVOICES.NOTIFICATIONS",
    disabledNotifications: true,
  },
  {
    id: REAL_ESTATE_CADASTRE_MODULE,
    visible: true,
    name: "MODULES.REAL_ESTATE_CADASTRE",
    notifications: "MODULES.REAL_ESTATE_CADASTRE.NOTIFICATIONS",
  },
  {
    id: BANKRUPTCY_REGISTER_MODULE,
    visible: true,
    name: "MODULES.BANKRUPTCY_REGISTER",
    notifications: "MODULES.BANKRUPTCY_REGISTER.NOTIFICATIONS",
  },
  {
    id: ACCEPT_READ_MODULE,
    visible: false,
    name: "MODULES.ACCEPT_READ",
    notifications: "MODULES.ACCEPT_READ.NOTIFICATIONS",
  },
  {
    id: REQUIREMENT_FOR_APPROVAL_MODULE,
    visible: true,
    name: "MODULES.REQUIREMENT_FOR_APPROVAL",
    notifications: "MODULES.REQUIREMENT_FOR_APPROVAL.NOTIFICATIONS",
    disabledNotifications: true,
  },
];
export const getModulesByUser = (user: any) => {
  return [
    {
      id: EVENTS_MODULE,
      visible: true,
      name: "MODULES.EVENTS",
      notifications: "MODULES.EVENTS.NOTIFICATIONS",
    },
    {
      id: "documents",
      visible: true,
      name: "MODULES.DOCUMENTS",
      notifications: "MODULES.DOCUMENTS.NOTIFICATIONS",
    },
    {
      id: MESSAGES_MODULE,
      visible: true,
      name: "MODULES.MESSAGES",
      notifications: "MODULES.MESSAGES.NOTIFICATIONS",
    },
    {
      id: USERS_MODULE,
      visible: true,
      name: "MODULES.OWNERS",
      notifications: "MODULES.OWNERS.NOTIFICATIONS",
      disabledNotifications: true,
    },
    {
      id: ROOMS_MODULE,
      visible: true,
      name: "MODULES.ROOMS",
    },
    {
      id: "reports",
      visible: true,
      name: "MODULES.REPORTS",
      notifications: "MODULES.REPORTS.NOTIFICATIONS",
    },
    {
      id: "survey",
      visible: true,
      name: "MODULES.SURVEYS",
      notifications: "MODULES.SURVEYS.NOTIFICATIONS",
    },
    {
      id: VOTE_MODULE,
      visible: true,
      name: "MODULES.VOTES",
      notifications: "MODULES.VOTES.NOTIFICATIONS",
      disabledNotifications: true,
    },
    {
      id: MEETING_OWNERS_MODULE,
      visible: true,
      name: "MODULES.OWNERS_MEETINGS",
      notifications: "MODULES.OWNERS_MEETINGS.NOTIFICATIONS",
    },
    {
      id: "gallery",
      visible: true,
      name: "MODULES.GALLERY",
    },

    {
      id: "svj",
      visible: true,
      name: "MODULES.SVJ",
    },

    {
      id: "contact",
      visible: true,
      name: "MODULES.CONTACT",
    },

    {
      id: "cash_desk",
      visible: true,
      name: "MODULES.CASH_DESK",
    },

    {
      id: "SVJ_FEE_MODULE",
      visible: hasCrudRole(SVJ_FEE_MODULE, user),
      name: "MODULES.ROOM_FEES",
    },

    {
      id: INVOICES_MODULE,
      visible: hasCrudRole(INVOICES_MODULE, user),
      name: "MODULES.INVOICES",
      notifications: "MODULES.INVOICES.NOTIFICATIONS",
      disabledNotifications: true,
    },
    {
      id: REAL_ESTATE_CADASTRE_MODULE,
      visible: hasCrudRole(REAL_ESTATE_CADASTRE_MODULE, user),
      name: "MODULES.REAL_ESTATE_CADASTRE",
      notifications: "MODULES.REAL_ESTATE_CADASTRE.NOTIFICATIONS",
    },
    {
      id: BANKRUPTCY_REGISTER_MODULE,
      visible: hasCrudRole(BANKRUPTCY_REGISTER_MODULE, user),
      name: "MODULES.BANKRUPTCY_REGISTER",
      notifications: "MODULES.BANKRUPTCY_REGISTER.NOTIFICATIONS",
    },
  ];
};

export const MODULES_ENUM = {
  events: "events",
};

export const getListOfModules = () => {};
